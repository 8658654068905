import { apiKey, apiKeyKlaviyoPublic, companyId, klaviyoListId, themeApiKey } from '../../config.js'
import helpers, { emailValidator } from '@/js/helpers.js'

export default {
  name: 'global',
  store() {
    return {
      upsellLoading: false,
      openBigProductModal: false,
      isMobileMenuVisible: false,
      isImageTagVisible1: false,
      isImageTagVisible2: false,
      isOverlayVisible: false,
      isAddReviewModalVisible: false,
      isProductVariantsVisible: false,
      desktopMenuDelay: null,
      isDesktopMenuVisible: false,
      isCollectionsFilterVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      activeProductVariantModal: null,
      activeProduct: null,
      options: [],
      selectedColor: null,
      selectedSize: null,
      selectedBundle: null,
      scrollLock: false,
      cart: null,
      trustpilot: [],
      loading: false,
      ups: null,
      klaviyo: {
        isValidEmail: true,
        first_name: '',
        success: false,
        loading: false,
        email: ''
      },
      money_format: '{{amount}}',
      init() {
        console.debug('Slayed Global Store Initialized.')

        this.getTrustpilot()

        // window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))

        this.initLiquidAJaxCart()

        Alpine.effect(() => {
          let isOverlayVisible = this.isOverlayVisible
          if (isOverlayVisible) {
            document.body.classList.add('overflow-hidden')
            document.querySelector('html').classList.add('overflow-hidden')
            document.body.classList.add('touch-none')
            document.querySelector('html').classList.add('touch-none')
          } else {
            document.body.classList.remove('overflow-hidden')
            document.querySelector('html').classList.remove('overflow-hidden')
            document.body.classList.remove('touch-none')
            document.querySelector('html').classList.remove('touch-none')
          }

        })

      },

      activeVariant() {
        this.activeProductVariantModal = this.activeProduct.variants.find(variant => {
          if (this.selectedSize && this.selectedColor) {
            return variant.title.includes(this.selectedSize) && variant.title.includes(this.selectedColor)
          } else if (this.selectedSize) {
            return variant.title.includes(this.selectedSize)
          } else if (this.selectedColor) {
            return variant.title.includes(this.selectedColor)
          } else if (this.selectedBundle) {
            return variant.title.includes(this.selectedBundle)
          }

          return false
        })
      },

      get productSize() {
        return this.options.find(opt => opt.name === 'Grootte')
      },

      get productColor() {
        return this.options.find(opt => opt.name === 'Kleur')
      },

      get productBundle() {
        return this.options.find(opt => opt.name === 'Aantal')
      },

      setColor(color) {
        this.selectedColor = color
        this.activeVariant()
      },

      setSize(size) {
        this.selectedSize = size
        this.activeVariant()
      },

      setBundle(bundle) {
        this.selectedBundle = bundle
        this.activeVariant()
      },

      submitCart() {
        this.upsellLoading = true
        console.debug('%c submitCart(color: %s, size: %s)', 'color:lime;', this.selectedColor, this.selectedSize)
        const variant = this.activeProduct.variants.find(variant => {
          if (this.selectedSize && this.selectedColor) {
            return variant.title.includes(this.selectedSize) && variant.title.includes(this.selectedColor)
          } else if (this.selectedSize) {
            return variant.title.includes(this.selectedSize)
          } else if (this.selectedColor) {
            return variant.title.includes(this.selectedColor)
          } else if (this.selectedBundle) {
            return variant.title.includes(this.selectedBundle)
          }

          return false
        })
        this.activeProductVariantModal = variant

        fetch('/cart/add.js', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: variant.id,
            quantity: 1
          })
        })
          .then(response => response.json())
          .then(data => {
            this.upsellLoading = false
            Alpine.store('Cart').fetchCart().then((cart) => {
              Alpine.store('Cart').cart = cart
              Alpine.store('global').isMinicartVisible = true
              Alpine.store('Cart').getCompareAtPrices()
              // this.loading = false
              this.isProductVariantsVisible = false
              Alpine.store('global').isMinicartVisible = false
            })
          })
          .catch((error) => {
            console.error('Error:', error)
          })

        console.debug('%c variant: %s', 'color:orangered;', JSON.stringify(variant, null, 4))
      },

      onShowProductModal(optionsJson) {
        // console.log('Product JSON:', JSON.stringify(JSON.parse(productJson), null, 4))
        // this.activeProduct = JSON.parse(productJson)
        this.options = JSON.parse(optionsJson)
        // console.log('Product JSON:', JSON.stringify(JSON.parse(productJson), null, 4))
        this.selectedColor = null
        this.selectedSize = null
        this.selectedBundle = null

        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].name === 'Grootte') {
            this.selectedSize = this.options[i].values[0]
          }
          if (this.options[i].name === 'Kleur') {
            this.selectedColor = this.options[i].values[0]
          }
          if (this.options[i].name === 'Aantal') {
            this.selectedBundle = this.options[i].values[0]
          }
        }
        // this.activeProductVariantModal.colors = variantColorsJson
        console.debug('Product:', this.activeProduct)
        this.activeVariant()
        // this.getSwatches()
        // this.takeUniqVariants(this.activeProductVariantModal)
        this.isProductVariantsVisible = true
      },

      takeUniqVariants(product) {
        const productVariants = this.activeProductVariantModal.variants
        const uniqueOptions1 = new Set()
        const uniqueOptions2 = new Set()

        // Iterate over the product variants
        for (const variant of productVariants) {
          // Add the options to the respective Sets
          uniqueOptions1.add(variant.option1)
          uniqueOptions2.add(variant.option2)
        }

        // Convert the Sets back to Arrays
        const uniqueOptionsArray1 = Array.from(uniqueOptions1)
        const uniqueOptionsArray2 = Array.from(uniqueOptions2)

        this.activeProductVariantModal.uniqueOptionsArray1 = uniqueOptionsArray1
        this.activeProductVariantModal.uniqueOptionsArray2 = uniqueOptionsArray2

        this.activeProductVariantModal.selectedOption1 = this.activeProductVariantModal.uniqueOptionsArray1[0]
        this.activeProductVariantModal.selectedOption2 = this.activeProductVariantModal.uniqueOptionsArray2[0]

        this.activeProductVariantModal.matchingVariant = this.activeProductVariantModal.variants[0]

        // Log the unique options
        console.debug(uniqueOptionsArray1)
        console.debug(uniqueOptionsArray2)
      },

      findSelectedVariant() {
        const productVariants = this.activeProductVariantModal.variants
        const selectedOption1 = this.activeProductVariantModal.selectedOption1
        const selectedOption2 = this.activeProductVariantModal.selectedOption2

        let matchingVariant

        // Iterate over the product variants
        for (const variant of productVariants) {
          // Check if the options of the variant match the selected options
          if (variant.option1 === selectedOption1 && variant.option2 === selectedOption2) {
            // If they match, store the id of the variant and break the loop
            matchingVariant = variant
            break
          }
        }

        // Log the id of the matching variant
        this.activeProductVariantModal.matchingVariant = matchingVariant
        console.debug('Matching Variant:', matchingVariant)
      },

      closeImageTagModal() {
        this.isImageTagVisible1 = false
        this.isImageTagVisible2 = false
      },

      closeBigProductModal() {
        this.openBigProductModal = false
      },

      async getCartData() {
        const query = `
    {
      cart {
        id
        lineItems(first: 100) {
          edges {
            node {
              id
              quantity
              variant {
                id
                price
                compareAtPrice
                product {
                  title
                }
              }
            }
          }
        }
      }
    }
  `

        const response = await fetch('/api/2024-04/graphql.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': `${themeApiKey}`
          },
          body: JSON.stringify({
            query: query
          })
        })

        const data = await response.json()

        if (data.data.cart) {
          Alpine.store('Cart').cart = data.data.cart
        } else {
          throw new Error('No cart found')
        }
      },

      async getSwatches() {
        const query = `
        query($productId: ID!) {
          product(id: $productId) {
            id
            title
            variants(first: 10) {
              edges {
                node {
                  id
                  title
                  
                  metafields(identifiers: [{ namespace: "swatches", key: "color" }]) {
                    id
                    namespace
                    key
                    value
                  }
                }
              }
            }
          }
        }
        `
        const response = await fetch('/api/2024-04/graphql.json', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Shopify-Storefront-Access-Token': `${themeApiKey}`
          },
          body: JSON.stringify({
            query: query,
            variables: {
              productId: this.activeProduct.id
            }
          })
        })

        const data = await response.json()
        console.debug('Swatches:', data)
        return data
      },

      async getCollectionByHandle(collection) {
        try {
          Alpine.store('collections').loading = true
          collection.loading = true
          const query = `
    {
      collectionByHandle(handle: "${collection.handle}") {
        id
        title
        handle
        description
        seo: metafield(namespace: "seo", key: "text") {
          value
        }
        banner: metafield(namespace: "bottom", key: "banner") {
          value
          reference {
            ... on Metaobject {
              fields {
                key
                value
                reference {
                  ... on MediaImage {
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        quizBanner: metafield(namespace: "quiz", key: "banner") {
          value
          reference {
            ... on Metaobject {
              fields {
                key
                value
                references(first: 10) {
                  edges {
                    node {
                      ... on MediaImage {
                        image {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        products(first: 100) {
          edges {
            node {
              id
              title
              handle
              tags
              featuredImage {
                url
              }
              priceRange {
                minVariantPrice {
                  amount
                }
              }
              compareAtPriceRange {
                minVariantPrice {
                  amount
                }
              }
              shortDescription: metafield(namespace: "custom", key: "short_description") {
                value
              }
              galleryBadge: metafield(namespace: "gallery", key: "badge") {
                value
                reference {
                  ... on MediaImage {
                    image {
                      url
                    }
                  }
                }
              }
              fakeLastPrice: metafield(namespace: "fake", key: "last_price") {
                value
              }
              fakeComparePrice: metafield(namespace: "fake", key: "compare_price") {
                value
              }
              fakeDiscount: metafield(namespace: "fake", key: "discount") {
                value
              }
              additionalImage: metafield(namespace: "additional", key: "image") {
                value
                reference {
                  ... on MediaImage {
                    image {
                      url
                    }
                  }
                }
              }
              variants(first: 100) {
                edges {
                  node {
                    id
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `

          const response = await fetch('/api/2024-04/graphql.json', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Shopify-Storefront-Access-Token': `${themeApiKey}`
            },
            body: JSON.stringify({ query: query })
          })

          const data = await response.json()
          console.debug('Data:', data)

          if (data.data.collectionByHandle) {
            // Function to parse and extract text from the value
            function extractTextFromValue(value) {
              try {
                const parsedValue = JSON.parse(value)
                const children = parsedValue.children || []

                let text = ''
                children.forEach(child => {
                  if (child.type === 'paragraph' && child.children) {
                    child.children.forEach(subChild => {
                      if (subChild.type === 'text') {
                        if (subChild.italic) {
                          text += `<em>${subChild.value}</em>`
                        }
                          // else {
                          //   text += `<p>${subChild.value}</p>`
                        // }

                        else if (subChild.bold) {
                          text += `<b>${subChild.value}</b>`
                        } else {
                          text += `<p>${subChild.value}</p>`
                        }
                      }
                    })
                  }

                  if (child.type === 'heading' && child.children) {
                    console.log('Heading')
                    child.children.forEach(subChild => {
                      if (subChild.type === 'text') {
                        if (child.level === 2) {
                          text += `<h2 class="mb-6">${subChild.value}</h2>`
                        } else if (child.level === 3) {
                          text += `<h3 class="mb-6">${subChild.value}</h3>`
                        } else {
                          text += subChild.value
                        }
                      }

                    })
                  }
                })

                return text
              } catch (e) {
                // If parsing fails, return the raw value
                return value
              }
            }

            const products = data.data.collectionByHandle.products.edges.map(product => {
              return {
                id: product.node.id,
                title: product.node.title,
                handle: product.node.handle,
                price: product.node.priceRange.minVariantPrice.amount,
                compareAtPrice: product.node.compareAtPriceRange.minVariantPrice.amount,
                featuredImage: product.node.featuredImage.url,
                metafield: product.node.additionalImage,
                shortDescription: product.node.shortDescription
              }
            })

            console.debug('Collection:', data.data.collectionByHandle)

            data.data.collectionByHandle.products.edges.forEach(product => {
              product.node.priceRange.minVariantPrice.amount = parseFloat(product.node.priceRange.minVariantPrice.amount) * 100
              product.node.compareAtPriceRange.minVariantPrice.amount = parseFloat(product.node.compareAtPriceRange.minVariantPrice.amount) * 100
            })

            Alpine.store('collections').currentCollection = data.data.collectionByHandle

            // Extracting and echoing the values
            const collectionBanner = {}

            if (Alpine.store('collections').currentCollection?.banner?.reference?.fields) {
              Alpine.store('collections').currentCollection.banner.reference.fields.forEach(field => {
                const key = field.key
                const value = extractTextFromValue(field.value)
                Alpine.store('collections').bannerData[key] = value
              })

              console.debug('Collection Banner Data:', Alpine.store('collections').bannerData)
            } else {
              // If there is no banner, set the bannerData to an empty object
              Alpine.store('collections').bannerData.title = ''
              Alpine.store('collections').bannerData.name_1 = ''
              Alpine.store('collections').bannerData.name_2 = ''
              Alpine.store('collections').bannerData.name_3 = ''
              Alpine.store('collections').bannerData.name_4 = ''
            }


            // Quiz Banner
            if (data.data.collectionByHandle?.quizBanner?.reference?.fields) {
              const quizBannerData = {};
              data.data.collectionByHandle.quizBanner.reference.fields.forEach(field => {
                const key = field.key;
                if (field.references && field.references.edges.length > 0) {
                  // This is an image field
                  quizBannerData[key] = field.references.edges.map(edge => edge.node.image.url);
                } else {
                  // This is a text field
                  quizBannerData[key] = extractTextFromValue(field.value);
                }
              });

              Alpine.store('collections').quizBannerData = quizBannerData;
              console.debug('Collection Quiz Banner Data:', Alpine.store('collections').quizBannerData);
            } else {
              // If there is no banner, set the bannerData to an empty object
              Alpine.store('collections').quizBannerData.title = ''
              // Alpine.store('collections').quizBannerData.name_1 = ''
              // Alpine.store('collections').quizBannerData.name_2 = ''
              // Alpine.store('collections').quizBannerData.name_3 = ''
              // Alpine.store('collections').quizBannerData.name_4 = ''
            }

            // console.debug('Collection SEO Data:', Alpine.store('collections').currentCollection.seo);


            // Extracting and echoing the values
            if (Alpine.store('collections').currentCollection?.seo?.value !== '') {
              // Alpine.store('collections').currentCollection.seo.value.forEach(field => {
              //   const key = field.key;
              //   const value = extractTextFromValue(field.value);
              //   Alpine.store('collections').seoData[key] = value;
              // });

              Alpine.store('collections').seoData.seo_text = extractTextFromValue(Alpine.store('collections').currentCollection?.seo?.value)

              console.debug('!!!!!!!!!Collection SEO Data:', Alpine.store('collections').seoData)
            } else {
              Alpine.store('collections').seoData = { seo_text: '' }
            }


            Alpine.store('collections').pageNumber = 0

            requestAnimationFrame(() => {
              console.debug('Refreshing AOS')
              AOS.refresh()
            })

            // Change the current URL to match the handle of the current collection
            const newUrl = window.location.protocol + '//' + window.location.host + '/collections/' + data.data.collectionByHandle.handle
            window.history.pushState({ path: newUrl }, '', newUrl)
            Alpine.store('collections').loading = false
            collection.loading = false
            setTimeout(() => {
              this.isCollectionsFilterVisible = false
              // window.scrollTo({
              //   top: 0,
              //   behavior: 'smooth'
              // })
            }, 500)
          } else {
            console.debug('No collection found with this handle')
          }
        } catch (error) {
          console.error('Error:', error)
        }
      },

      get bodyClasses() {
        let classes = []

        if (this.isImageTagVisible1 || this.isImageTagVisible2) {
          document.querySelector('html').classList.add('overflow-hidden')
        } else {
          document.querySelector('html').classList.remove('overflow-hidden')
        }

        if (this.isMobileMenuVisible) {
          document.querySelector('html').classList.add('mobile-menu-visible')
          // classes.push('mobile-menu-visible')
        } else {
          document.querySelector('html').classList.remove('mobile-menu-visible')
        }

        return classes || ''
      },

      pluralize(word, language) {
        return helpers.pluralize(word, language)
      },

      openMobileMenu() {
        this.isMobileMenuVisible = true
      },

      closeMobileMenu() {
        this.isMobileMenuVisible = false
      },

      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
      },

      initLiquidAJaxCart() {
        document.addEventListener('liquid-ajax-cart:request-end', (event) => {
          const { requestState, cart, previousCart, sections } = event.detail
          console.debug('Request State:', requestState)

          if (requestState.requestType === 'add') {
            console.debug('Added to cart')
            if (requestState.responseData?.ok) {
              Alpine.store('Cart').fetchCart().then((cart) => {
                Alpine.store('Cart').cart = cart
                this.isMinicartVisible = true
                console.debug('CLOSE')
                this.isProductVariantsVisible = false
                Alpine.store('product').expandStickyAdc = false
                Alpine.store('Cart').getCompareAtPrices()
                Alpine.store('Cart').loading = false
                this.openBigProductModal = false
              })
            }
          }

          // this.cart = cart
          // console.log('Cart:', cart)
        })
      },

      savedPercentage(compareAtPrice, price) {
        return Math.round((compareAtPrice - price) / compareAtPrice * 100)
      },

      initEscListener() {
        window.addEventListener('keydown', (e) => {
          if (e.key === 'Escape') {
            this.closeOverlay()
          }
        })
      },

      closeOverlay() {
        console.debug('CLOSE')
        this.isImageTagVisible1 = false
        this.isImageTagVisible2 = false
        this.openBigProductModal = false
        this.isAddReviewModalVisible = false
      },

      formatMoney(cents, format) {
        if (typeof cents == 'string') {
          cents = cents.replace('.', '')
        }
        var value = ''
        var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/
        var formatString = (format || this.money_format)

        function defaultOption(opt, def) {
          return (typeof opt == 'undefined' ? def : opt)
        }

        function formatWithDelimiters(number, precision, thousands, decimal) {
          precision = defaultOption(precision, 2)
          thousands = defaultOption(thousands, '.')
          decimal = defaultOption(decimal, ',')

          if (isNaN(number) || number == null) {
            return 0
          }

          number = (number / 100.0).toFixed(precision)

          var parts = number.split('.'),
            dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
            cents = parts[1] ? (decimal + parts[1]) : ''

          return dollars + cents
        }

        let match = formatString.match(placeholderRegex)
        if (match && match[1]) {
          switch (match[1]) {
            case 'amount':
              value = formatWithDelimiters(cents, 2)
              break
            case 'amount_no_decimals':
              value = formatWithDelimiters(cents, 0)
              break
            case 'amount_with_comma_separator':
              value = formatWithDelimiters(cents, 2, '.', ',')
              break
            case 'amount_no_decimals_with_comma_separator':
              value = formatWithDelimiters(cents, 0, '.', ',')
              break
          }
        }

        return formatString.replace(placeholderRegex, value)
      },

      formatPrice(price, includeCurrency = true) {
        // Convert the price to a number
        price = parseFloat(price)

        // Check if the price is a valid number
        if (isNaN(price)) {
          return 'Invalid price'
        }

        // Check if the price is an integer (no decimal part)
        if (price === parseInt(price, 10)) {
          // Format integer price
          let formattedPrice = (price / 100).toFixed(2)
          if (includeCurrency) {
            formattedPrice = '€' + formattedPrice
          }
          return formattedPrice
        } else {
          // Format float price
          let formattedPrice = (price).toFixed(2)
          if (includeCurrency) {
            formattedPrice = '€' + formattedPrice
          }
          return formattedPrice
        }
      },

      formatNumber(number) {
        // Convert the number to a string
        const numberString = parseFloat(number)
        const cleanNumber = numberString.toString().replace(/[^0-9.]/g, '')

        // If the number is less than 10000, format it as 'xxxx.xx'
        if (cleanNumber < 10000) {
          return (cleanNumber / 100).toFixed(2).replace('.', ',')
        }

        // Otherwise, format it as before
        const [integerPart, decimalPart = '00'] = cleanNumber.split('.')
        let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (formattedIntegerPart === '') {
          formattedIntegerPart = '0'
        }
        const integerPartWithDot = formattedIntegerPart.length > 3 ?
          formattedIntegerPart.slice(0, -3) + '.' + formattedIntegerPart.slice(-3) :
          formattedIntegerPart
        const truncatedDecimalPart = decimalPart.padEnd(2, '0').substring(0, 2)
        return (integerPartWithDot + ',' + truncatedDecimalPart).replace(',.', '.')
      },

      getImageUrl(originalUrl, imageSize) {
        let queryIndex = originalUrl.indexOf('?')
        let queryParam = ''
        if (queryIndex !== -1) {
          let baseUrl = originalUrl.substring(0, queryIndex)
          queryParam = originalUrl.substring(queryIndex) // Remove 'let' here
        }
        const baseUrl = originalUrl.split('?')[0]
        return `${baseUrl}?width=${imageSize}`
      },

      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0

        this.isWindowScrolled = isScrolled
        document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
      },

      getTrustpilot() {
        this.loading = true
        const url = `https://api.trustpilot.com/v1/business-units/${companyId}?apikey=${apiKey}`
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.trustpilot = data

            // console.log('Trustpilot Data:', this.trustpilot)
          })
          .catch(error => {
            console.error('Error:', error)
          })
          .finally(() => {
            this.loading = false
          })
      },

      validateEmail() {
        const validator = emailValidator()
        validator.email = this.klaviyo.email
        validator.validateEmail()
        this.klaviyo.isValidEmail = validator.isValid
      },

      sendDataToKlaviyo() {
        this.klaviyo.loading = true
        this.validateEmail()
        if (!this.klaviyo.isValidEmail) {
          setTimeout(() => {
            this.klaviyo.isValidEmail = true
          }, 5000)
          this.klaviyo.loading = false
        } else {
          const options = {
            method: 'POST',
            headers: { revision: '2024-05-15', 'content-type': 'application/json' },
            body: JSON.stringify({
              data: {
                type: 'subscription',
                attributes: {
                  custom_source: 'Homepage footer signup form',
                  profile: {
                    data: {
                      type: 'profile',
                      attributes: {
                        email: this.klaviyo.email,
                        anonymous_id: '',
                        first_name: this.klaviyo.first_name,
                        last_name: '',
                        organization: '',
                        title: '',
                        image: '',
                        location: {
                          address1: '',
                          address2: '',
                          city: '',
                          country: '',
                          region: '',
                          zip: '',
                          timezone: '',
                          ip: ''
                        },
                        properties: { newKey: '' }
                      },
                      meta: {
                        patch_properties: { append: { newKey: '' }, unappend: { newKey: '' }, unset: '' }
                      }
                    }
                  }
                },
                relationships: { list: { data: { type: 'list', id: `${klaviyoListId}` } } }
              }
            })
          }

          fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${apiKeyKlaviyoPublic}`, options)
            .then(response => {
              if (response.ok) {
                this.klaviyo.loading = false
                this.klaviyo.email = ''
                this.klaviyo.first_name = ''
                this.klaviyo.success = true
                setTimeout(() => {
                  this.klaviyo.success = false
                }, 5000)
              }
            })
            .then(response => console.log(response))
            .then(() => {

            })
            .catch(err => console.error(err))
        }
      }

    }

  }
}
